import { tint } from 'polished';

const primaryColor = '#22262c';
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = '#2abeef';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
};
